<template>
  <div class="bannerContainer">
    <div class="title">BANNER设置</div>
    <div>
      <el-row class="panel-group bannerWrap">
        <draggable v-bind="dragOptions" v-model="imgList" @end="end">
          <el-col
            v-for="(item, index) in imgList"
            :xs="12"
            :sm="6"
            :md="6"
            :lg="6"
            :key="item.id"
            class="card-panel-col"
          >
            <div class="col" @click="addEdit(item, index)">
              <span class="num">{{ index + 1 }}</span>
              <img
                v-if="item.imageUrl"
                src="../../assets/delete.png"
                alt
                class="delete"
                @click.stop="deleteBanner(index)"
              />
              <img
                v-if="item.imageUrl"
                :src="item.imageUrl"
                alt
                class="banner"
              />
              <div v-else class="newContain">
                <img src="../../assets/add.png" alt class="add" />
                <p>新增banner</p>
              </div>
            </div>
          </el-col>
        </draggable>
      </el-row>
    </div>
    <div class="des">拖动BANNER预览可以调换BANNER显示顺序</div>
    <Uploaddialog
      ref="uploadDialog"
      :visible.sync="addVisible"
      :bannerdetail="bannerdetail"
      @changeBanner="changeBanner"
    />
  </div>
</template>
<script>
import Uploaddialog from "@/components/uploaddialog";
import draggable from "vuedraggable";
import {
  getBannerList,
  saveBannerList,
  getPagesUrl,
  getCommonPagesUrl,
} from "@/apis/user.js";
export default {
  components: {
    Uploaddialog,
    draggable,
  },
  data() {
    return {
      addVisible: false, // 对话框显示隐藏
      imgList: [],
      bannerdetail: {},
      editable: true, // 是否允许拖动
    };
  },
  computed: {
    dragOptions() {
      return {
        group: "banner",
        animation: 150,
        ghostClass: "sortable-ghost",
        chosenClass: "chosenClass",
        scroll: true,
        scrollSensitivity: 200,
        disabled: !this.editable,
      };
    },
  },
  mounted() {
    this.getBannerListInit();
    this.getPagesList();
  },
  methods: {
    getBannerListInit() {
      getBannerList().then((res) => {
        var imgList = [{}, {}, {}, {}];
        for (let i = 0; i < imgList.length; i++) {
          imgList[i] = { sort: i };
          for (let j = 0; j < res.result.length; j++) {
            if (res.result[j].sort === i) {
              imgList[i] = res.result[j];
            }
          }
        }
        this.imgList = imgList;
      });
    },
    addEdit(item) {
      this.bannerdetail = "";
      var bannerdetail = JSON.parse(JSON.stringify(item));
      this.bannerdetail = bannerdetail;
      this.$refs.uploadDialog.getNewsTitles();
      this.addVisible = true;
    },
    deleteBanner(index) {
      this.$handleConfirm("请确认是否要删除这张banner?")
        .then(() => {
          this.imgList.splice(index, 1, { sort: index });
          saveBannerList(this.imgList).then(() => {
            this.$message1("删除成功", "success");
          });
        })
        .catch((err) => {
          console.log("err", err); // cancel
        });
      //
    },
    // 修改图片或者新郑图片
    changeBanner(e) {
      this.imgList[e.sort] = e;
      saveBannerList(this.imgList).then(() => {
        if (!e.id) {
          this.$message1("新增成功", "success");
          getBannerList().then((res) => {
            for (let i = 0; i < res.result.length; i++) {
              if (res.result[i].sort === e.sort) {
                this.imgList[e.sort].id = res.result[i].id;
              }
            }
          });
        } else {
          this.$message1("保存成功", "success");
        }
      });
    },
    // 拖动结束
    end: function () {
      var imgList = this.imgList;
      for (var i = 0; i < imgList.length; i++) {
        // if (imgList[i] && imgList[i].id) {
        imgList[i].sort = i;
        // }
      }
      // this.imgList = imgList
      saveBannerList(imgList).then(() => {
        // //console.log(res)
      });
    },
    getPagesList() {
      getPagesUrl().then((res) => {
        getCommonPagesUrl().then((res1) => {
          var result = res.result.concat(res1.result).filter((item) => {
            return item.title !== "数说(通用)";
          });
          this.$store.commit("SET_HREFLIST", result);
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
.bannerContainer {
  // position:relative;
  padding: 40px 0 0 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 30px;
  }
  .elRowWrap {
    margin: 0 5%;
  }
  .bannerWrap {
    margin: 42px 5% 0;
    border: solid 1px #e5e5e5;
    border-radius: 18px;
    padding: 40px 32px;
    max-width: 980px;
    box-sizing: border-box;
  }
  .card-panel-col {
    padding: 10px 12px !important;
    .col {
      border: 1px dashed #e5e5e5;
      height: 88px;
      border-radius: 12px;
      position: relative;

      .num {
        position: absolute;
        width: 32px;
        height: 42px;
        left: -10px;
        top: -25px;
        font-family: Arial-Black;
        font-size: 36px;
        text-align: center;
        color: #b3b3b3;
      }
      .delete {
        width: 20px;
        height: 20px;
        position: absolute;
        right: -10px;
        top: -10px;
      }
      .banner {
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
      .newContain {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        p {
          line-height: 1;
          font-size: 16px;
          color: #999;
          margin-top: 6px;
        }
      }
    }
  }
  .des {
    font-size: 14px;
    color: #b3b3b3;
    line-height: 1;
    margin: 22px 0 0 86px;
  }
}
</style>
