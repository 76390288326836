<template>
  <div v-if="show" id="cropperWrap" class="cropperWrap">
    <div class="vue-cropper-box">
      <div class="vueCropperContent">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :output-size="option.outputSize"
          :output-type="option.outputType"
          :can-move="option.canMove"
          :info="option.info"
          :full="option.full"
          :can-scale="option.canScale"
          :auto-crop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :fixed="option.fixed"
          :fixed-number="fixedNumber"
          @realTime="realTime"
        />
      </div>
      <div class="pre">
        <p>预览图</p>
        <section class="pre-item">
          <div :style="previewStyle4">
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div>
        </section>
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="persent"
          class="progress"
        />
        <div class="btnCropperWrap">
          <el-button class="confirm" @click="onCubeImg">裁剪上传</el-button>
          <el-button class="confirm" @click="oriUpload">原图上传</el-button>
          <el-button class="cancel" @click="cancel">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { convertBase64UrlToBlob } from "@/utils/index";
import { fileAdd } from "@/utils/index";
import { getCookie } from "@/utils/auth";
import { CONFIG } from "@/utils/config.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cropperImgUrl: {
      type: String,
      default: "",
    },
    fileList: {
      type: Object,
      default: () => {},
    },
    path: {
      type: String,
      default: "",
    },
    fixedNumber: {
      type: Array,
      default: () => [7, 3],
    },
  },
  data() {
    return {
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        canMove: false,
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 700, // 默认生成截图框宽度
        autoCropHeight: 300, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [7, 3], // 截图框的宽高比例
        infoTrue: true,
        full: false,
      },
      previews: {},
      previewStyle4: {},
      persent: 0,
    };
  },
  watch: {
    cropperImgUrl() {
      this.option.img = this.cropperImgUrl;
    },
  },
  methods: {
    oriUpload() {
      // //console.log(this.fileList)
      // var fileNew = convertBase64UrlToBlob(this.cropperImgUrl, new Date().getTime() + '.jpeg')
      this.uploadSectionFile(this, this.fileList.raw);

      // 先将显示图片地址清空，防止重复显示
      this.option.img = "";
    },
    cancel() {
      // //console.log(123)
      this.$emit("cancelCropper", false);
    },
    onCubeImg() {
      // 获取cropper的截图的base64 数据
      this.$refs.cropper.getCropData((data) => {
        // 将剪裁后base64的图片转化为file格式
        var fileNew = convertBase64UrlToBlob(
          data,
          new Date().getTime() + ".jpeg"
        );
        // 将剪裁后的图片执行上传
        this.uploadSectionFile(this, fileNew);

        // 先将显示图片地址清空，防止重复显示
        this.option.img = "";
      });
    },
    // 实时预览函数
    realTime(data) {
      var previews = data;
      this.previewStyle4 = {
        width: previews.w + "px",
        height: previews.h + "px",
        overflow: "hidden",
        margin: "0",
        zoom: 300 / previews.w,
      };
      this.previews = data;
    },
    uploadSectionFile(that, fileNew) {
      var xhr, formData, json;

      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", CONFIG + this.path);
      xhr.setRequestHeader("auth", getCookie("Auth"));
      xhr.onload = function () {
        if (xhr.status !== 200) {
          that.$message1("图片上传失败,请重新上传", "error");
          return;
        }
        // //console.log(res)
        that.persent = 100;
        if (that.persent === 100) {
          setTimeout(() => {
            json = JSON.parse(xhr.responseText);
            that.$emit("cubeImg", {
              show: false,
              imgNew: json.location,
              id: json.id,
            });
            that.$message1("图片上传成功", "success");
            that.persent = 0;
          }, 1000);
        }
      };
      xhr.onerror = function () {
        that.$message1("图片上传失败,请重新上传", "error");
      };
      xhr.upload.addEventListener("progress", function (e) {
        // 添加 监听函数
        // xhr.upload.onprogress = function(e) {

        that.persent = Math.round((e.loaded / e.total) * 100);
        // //console.log(that.persent)
        // if (that.persent === 100) {
        //   setTimeout(() => {
        //     json = JSON.parse(xhr.responseText)
        //     that.$emit('cubeImg', { show: false, imgNew: json.location })
        //     that.$message1('图片上传成功', 'success')
        //     that.persent = 0
        //   }, 1000)
        // }
      });
      formData = new FormData();
      const file = fileNew;
      fileAdd(file).then((res) => {
        var index = file.name.lastIndexOf(".");
        var blobName = "";
        if (index > 0) {
          blobName = file.name.substr(0, index) + ".jpeg";
        } else {
          blobName = file.name + ".jpeg";
        }
        // //console.log(res)
        formData.append("file", res, blobName);
        xhr.send(formData);
      });
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/mixin";
#cropperWrap {
  .confirm {
    @include btnCardConfirm(80px, 36px, 14px);
  }
  .cancel {
    @include btnCardCancel(80px, 36px, 14px);
  }
}
</style>
<style lang="scss" scoped>
.pre {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin-left: 20px;
  position: relative;
  // height:150px;
  p {
    font-size: 24px;
    color: #888;
    line-height: 1;
  }
  .pre-item {
    // padding-right: 20px;
    border: 1px solid #ccc;
    background: #000;
    margin-top: 20px;
  }
  .btnCropperWrap {
    // position:absolute;
    // left:0;
    padding-bottom: 50px;
    margin-top: auto;
    display: flex;
  }
  .progress {
    margin-top: 20px;
  }
}

.cropperWrap {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background: #fff;
  border-radius: 13px;
  padding: 26px;
  border: 1px solid #ccc;
}
// .mark{
//   position:fixed;
//   left:0;
//   top:0;
//   right:0;
//   bottom:0;
//   z-index:2800;
//   background:rgba(0,0,0,0.5);
// }
.vue-cropper-box {
  display: flex;
}
.vueCropperContent {
  width: 500px;
  height: 500px;
}
</style>
