<template>
  <div class="uploadWrap">
    <el-upload
      ref="upload"
      :auto-upload="acceptType == 'video' ? true : false"
      :show-file-list="false"
      :on-change="handleChange"
      :on-success="handleVideoSuccess"
      :on-progress="handleVideoProgress"
      :limit="1"
      :accept="accept"
      :action="action"
      :headers="headers"
      name="file"
      class="uploadchoose"
    >
      <div
        v-if="!url"
        :style="{ width: bannerX + 'px', height: bannerY + 'px' }"
        class="uploadBtnWrap"
      >
        {{ videoFlag == true && acceptType == "video" ? "" : uploadName }}
        <el-progress
          v-if="videoFlag == true && acceptType == 'video'"
          :width="Number(80)"
          :percentage="videoUploadPercent"
          type="circle"
        />
      </div>
      <div
        v-if="url && acceptType == 'img'"
        :style="{ width: bannerX + 'px', height: bannerY + 'px' }"
        class="showImgWrap"
        @mouseover="mouseover"
      >
        <img
          :src="url"
          :style="{ width: bannerX + 'px', height: bannerY + 'px' }"
          class="uploadBtnUrl"
        />
        <p v-if="uploadFlag" class="upVideo" @mouseleave="mouseout">重新上传</p>
      </div>
      <div
        v-if="acceptType == 'video' && url"
        :style="{ width: bannerX + 'px', height: bannerY + 'px' }"
        class="showImgWrap"
        @mouseover="mouseover"
      >
        <video
          v-if="!videoFlag"
          :src="url"
          :style="{
            width: bannerX + 'px',
            height: bannerY + 'px',
            borderRadius: '10px',
            background: '#000',
          }"
          width="210"
          height="90"
          controls
        />
        <p
          v-if="uploadFlag && !videoFlag"
          class="upVideo"
          @mouseleave="mouseout"
        >
          <span @click.stop="upload">预览</span><span>重新上传</span>
        </p>
        <el-progress
          v-if="videoFlag == true && acceptType == 'video'"
          :width="Number(80)"
          :percentage="videoUploadPercent"
          type="circle"
          class="progress"
        />
      </div>
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      custom-class="uploadDialog"
      append-to-body
      @close="close"
    >
      <video
        v-if="dialogVisible"
        :src="url"
        width="490"
        height="210"
        controls
        style="border-radius: 10px"
      />
    </el-dialog>
  </div>
</template>
<script>
import { CONFIG } from "@/utils/config.js";
import { getCookie } from "@/utils/auth";
export default {
  props: {
    fileList: {
      type: Object,
      default: () => {},
    },
    uploadtype: {
      type: String,
      default: "",
    },
    filePath: {
      type: String,
      default: "",
    },
    uploadName: {
      type: String,
      default: "上传图片",
    },
    url: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: ".jpg,.jpeg,.png,.JPG,.JPEG",
    },
    acceptType: {
      type: String,
      default: "img",
    },
    bannerSize: {
      type: Object,
      default: () => {
        return {
          bannerX: 700,
          bannerY: 300,
        };
      },
    },
  },
  data() {
    return {
      action: CONFIG + this.filePath,
      headers: {
        auth: getCookie("Auth"),
      },
      videoFlag: false,
      videoUploadPercent: 0,
      uploadFlag: false,
      dialogVisible: false,
    };
  },
  computed: {
    bannerX() {
      return parseInt((this.bannerSize.bannerX / 100) * 30);
    },
    bannerY() {
      return parseInt((this.bannerSize.bannerY / 100) * 30);
    },
  },
  methods: {
    // 上传状态变化
    handleChange(file) {
      if (file.status === "success") {
        this.$emit("update:fileList", {});
        this.$refs.upload.clearFiles();
      } else if (file.status === "fail") {
        this.$emit("update:fileList", {});
        this.$refs.upload.clearFiles();
      } else {
        if (this.acceptType === "video") {
          if (file.size / 1024 / 1024 > 20) {
            this.$message1("上传视频的大小不能超过20M", "warning");
            this.$refs.upload.clearFiles();
            return;
          }
          this.videoUploadPercent = 0;
          // this.$emit('chooseVideo', { location: '', id: '' })
          // this.fileList = file
        } else {
          if (file.size / 1024 / 1024 > 2) {
            this.$message1("上传图片的大小不能超过2M", "warning");
            this.$refs.upload.clearFiles();
            return;
          }
          var data = window.URL.createObjectURL(new Blob([file.raw]));

          this.$emit("chooseImage", {
            img: data,
            fileList: file,
            item: this.item,
            uploadtype: this.uploadtype,
            filePath: this.filePath,
          });
          this.$refs.upload.clearFiles();
        }
      }
    },
    // 取消dialog
    cancelDialog() {
      this.$emit("update:fileList", {});
      this.$refs.upload.clearFiles();
      this.$refs.upload.abort();
    },
    del() {
      this.$emit("delImg", { item: this.item, uploadtype: this.uploadtype });
    },
    handleVideoProgress(event, file) {
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percentage);
    },
    handleVideoSuccess(res) {
      // 获取上传图片地址
      // console.log(res)
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      if (res.code === 0) {
        this.$emit("chooseVideo", { location: res.location, id: res.id });
        // this.$emit('update:fileList', {})
        // this.videoForm.videoUploadId = res.data.uploadId;
        // this.videoForm.Video = res.data.uploadUrl;
      } else {
        this.$message.error("视频上传失败，请重新上传！");
      }
    },
    mouseover() {
      this.uploadFlag = true;
    },
    mouseout() {
      this.uploadFlag = false;
    },
    upload() {
      // console.log(11)
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/mixin";
.uploadDialog {
  width: 530px;
  border-radius: 10px;
}
.uploadWrap {
  display: flex;
  // width: 210px;
  .uploadchoose {
    flex: 1;
  }
  .el-upload {
    width: 100%;
  }
  .uploadBtnWrap {
    width: 210px;
    height: 90px;
    background: #fff;
    border: 1px dashed #ccc;
    color: #808080;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .showImgWrap {
    position: relative;
    width: 210px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    .uploadBtnUrl {
      width: 210px;
      height: 90px;
      border-radius: 10px;
      vertical-align: top;
    }
    .upVideo {
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      line-height: 30px;
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      span:nth-of-type(1) {
        margin-right: 10px;
      }
    }
  }
}
</style>
