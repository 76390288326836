<template>
  <div class="uploadPdfWrap">
    <el-upload
      v-if="!disabled"
      ref="upload"
      :auto-upload="true"
      :on-change="handleChange"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-error="handleError"
      :on-success="handSuccess"
      :before-upload="beforeUpload"
      :on-exceed="exceed"
      :show-file-list="showFileList"
      :file-list="list"
      :limit="limit"
      :action="action"
      :headers="headers"
      :multiple="multiple"
      accept=".pdf,.PDF,.doc,.DOC,.docx"
      name="file"
      class="uploadchoose"
    >
      <div class="prevWrap">
        <div>{{ fileName || "点击选择PDF文件" }}</div>
        <div class="priv">上传</div>
      </div>
    </el-upload>
  </div>
</template>
<script>
import { CONFIG } from "@/utils/config";
import { getCookie } from "@/utils/auth";

export default {
  props: {
    limit: {
      // 控制上传文件的数量限制 默认1
      type: Number,
      default: 1,
    },
    showFileList: {
      // 是否显示文件列表
      type: Boolean,
      default: true,
    },
    path: {
      // 路径配合和action组合使用
      type: String,
      default: "project",
    },
    pdfList: {
      // 文件列表
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      action: CONFIG + this.path,
      headers: { auth: getCookie("Auth") },
      UpLoadFilesLength: 0,
      repeatMessage: true,
      list: [],
    };
  },
  watch: {
    pdfList: {
      handler(newValue) {
        this.list = [...newValue];
        this.UpLoadFilesLength = newValue.length || 0;
      },
      immediate: true,
    },
  },
  methods: {
    handleChange() {},
    beforeUpload(file) {
      var index = this.list.findIndex((item) => item.name === file.name);
      if (index !== -1 && this.limit !== 1) {
        if (this.repeatMessage) {
          this.$message1("请不要上传重复的文件", "warning");
          this.repeatMessage = false;
        }
        setTimeout(() => {
          this.repeatMessage = true;
        }, 500);
        this.hasRepeat = true;
        return false;
      }
      if (this.limit === 1) {
        if (file.size / 1024 / 1024 > 10) {
          this.$message1("上传的大小文件不能超过10M", "warning");
          this.hasRepeat = true;
          return false;
        }
      }
    },
    handleRemove(file) {
      var index;
      if (file.status === "ready") {
        //
      } else {
        index = this.list.findIndex((item) => item.name === file.name);
        this.list.splice(index, 1);
        this.UpLoadFilesLength--;
      }
      this.$emit("update:pdfList", this.list);
    },
    handleError() {
      this.$message1("上传失败,请重新上传", "error");
    },
    beforeRemove(file) {
      if (this.hasRepeat) {
        this.hasRepeat = false;
      } else {
        return this.$alertMsgBox(`确定移除 ${file.name}？`);
      }
    },
    handSuccess(res, file, fileList) {
      if (this.limit === 1) {
        if (res.code === 0) {
          this.pdfList.push({
            url: res.location,
            status: file.status,
            name: file.name,
            uid: file.uid,
            id: res.id,
          });
          this.$message1("上传成功", "success");
          this.$emit("update:pdfList", this.pdfList);
        } else {
          this.$message1("上传失败,请重新上传", "error");
          this.$emit("update:pdfList", []);
        }
      } else {
        if (this.$refs.upload.uploadFiles) {
          const length = this.$refs.upload.uploadFiles.length;
          this.UpLoadFilesLength++; // 全局变量，用来计算upLoadSuccess方法调用次数
          if (this.UpLoadFilesLength === length) {
            this.list = fileList;
            this.$emit("update:pdfList", fileList);
            // this.UpLoadAllFilesLength = fileList.length // 如果方法调用的次数和文件列表的长度相同，说明所有文件都上传完毕，将该全局变量置0
            this.resErrorStr(); // 该函数处理每个文件上传错误情况下response拼接
          }
        }
      }
    },
    resErrorStr() {
      if (this.$refs.upload.uploadFiles) {
        // 如果存在这个值
        const filesList = this.$refs.upload.uploadFiles.slice();
        let UpLoadAllErrorStr = ""; // 错误信息拼接变量
        for (let i = 0; i < filesList.length; i++) {
          if (filesList[i].response) {
            if (filesList[i].response.code !== 0) {
              // 如果该文件上传后返回的状态值不是200，则说明该文件上传错误
              UpLoadAllErrorStr += `${filesList[i].response.message}`;
            }
          }
        }
        if (!UpLoadAllErrorStr) {
          this.$message1("上传文件成功！", "success");
        } else {
          this.$message1("部分文件上传失败", "error");
        }
        this.$refs.upload.uploadFiles = []; // 调用完成之后将值置空，防止再次上传将上次结果也记录下来
      }
    },
    exceed() {
      this.$message1(`最多上传${this.limit}个文件`, "warning");
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
  },
};
</script>
<style lang="scss">
.pdfDialog {
  max-height: calc(100vh - 158px);
  height: calc(100vh - 158px) !important;
  border-radius: 13px !important;
  overflow: hidden;
  width: 600px;
  z-index: 19999;
  .el-dialog__body {
    overflow-y: scroll;
  }
}
</style>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";

.uploadPdfWrap {
  display: flex;
  // margin-top: 10px;
  .uploadchoose {
    max-width: 440px;
    width: 440px;
  }

  .prevWrap {
    width: 440px;
    display: flex;
    max-width: 440px;
    box-sizing: border-box;
    // border: solid 1px #e5e5e5;
    align-items: center;
    height: 40px;
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #808080;
    }
    > div:nth-of-type(1) {
      flex: 1;
      border: solid 1px #e5e5e5;
      padding: 0 10px 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    > div:nth-of-type(2) {
      cursor: pointer;
      //  @include button(90px, 40px, #fff, 16px, #ff0087, 4px);
      @include btnCardConfirm(90px, 40px, 16px);
    }
  }
  .confirm {
    min-width: 114px;
    @include btnCardConfirm(90px, 40px, 16px);
  }
}
</style>
