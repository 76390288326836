<template>
  <div class="dialogContain">
    <el-dialog
      :visible.sync="visible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :lock-scroll="true"
      custom-class="dialog"
    >
      <el-form
        ref="dialogform"
        :model="bannerForm"
        :rules="bannerRules"
        @submit.native.prevent
      >
        <p class="title">请选择轮播图的内容(图片或者视频)</p>
        <div class="elItem">
          <el-form-item>
            <el-select
              v-model="bannerForm.bannerType"
              placeholder="请选择类型"
              value-key="bannerType"
              class="typeSelect"
              @change="changeBannerType"
            >
              <el-option
                v-for="item in bannerContentList"
                :key="item.bannerType"
                :label="item.name"
                :value="item.bannerType"
              />
            </el-select>
          </el-form-item>
          <p v-if="bannerForm.bannerType === 0" class="tips">
            图片大小{{ bannerSize.bannerX }}*{{ bannerSize.bannerY }}小于2M
          </p>
          <p v-if="bannerForm.bannerType === 1" class="tips">
            视频封面图大小{{ bannerSize.bannerX }}*{{
              bannerSize.bannerY
            }}小于2M和视频小于20M,mp4格式
          </p>
        </div>
        <div class="bannerSelectWrap">
          <div v-if="bannerForm.bannerType === 0" class="bannerUploadWrap">
            <new-upload
              ref="uploadWrap"
              :file-list="fileList"
              :file-path="'/banner/1/JpegQ85W750'"
              :upload-name="'点击上传图片'"
              :uploadtype="'bannerImage'"
              :url="bannerForm.imageUrl"
              :banner-size="bannerSize"
              @chooseImage="chooseImage"
            />
          </div>
          <div v-if="bannerForm.bannerType === 1" class="bannerUploadWrap">
            <new-upload
              ref="uploadWrap2"
              :file-list="fileList"
              :file-path="'/banner/1/JpegQ85W750'"
              :upload-name="'点击上传视频封面图片'"
              :uploadtype="'bannerImage'"
              :url="bannerForm.imageUrl"
              :banner-size="bannerSize"
              @chooseImage="chooseImage"
            />
            <p style="margin-right: 20px" />
            <new-upload
              ref="uploadWrap3"
              :file-list="fileList"
              :file-path="'video/9/null'"
              :upload-name="'点击上传视频'"
              :uploadtype="'bannerVideo'"
              :url="bannerForm.videoUrl"
              :accept="'.mp4'"
              :accept-type="'video'"
              :banner-size="bannerSize"
              @chooseVideo="chooseVideo"
            />
          </div>
        </div>
        <p class="title">请选择banner链接的内容(若选择视频,以下内容无法选择)</p>
        <div class="elItem">
          <el-form-item>
            <el-select
              v-model="bannerForm.targetType"
              :disabled="bannerForm.bannerType === 1"
              placeholder="请选择类型"
              value-key="targetType"
              class="typeSelect"
              clearable
              @clear="clear"
              @change="changeType"
            >
              <el-option
                v-for="item in bannerTypeList"
                :key="item.targetType"
                :label="item.name"
                :value="item.targetType"
              />
            </el-select>
          </el-form-item>
          <div class="selectWrap">
            <el-form-item
              v-if="bannerForm.targetType === 0"
              class="typeItem"
              prop="newsId"
            >
              <el-select
                v-model="bannerForm.newsId"
                :disabled="bannerForm.bannerType === 1"
                placeholder="请输入新闻标题，或关键字"
                value-key="newsId"
                class="typeSelect newsSelect"
                filterable
              >
                <el-option
                  v-for="item in titleList"
                  :key="item.newsId"
                  :label="item.title"
                  :value="item.newsId"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="bannerForm.targetType === 2"
              class="typeItem"
              prop="pageUrl"
            >
              <el-select
                v-model="bannerForm.pageUrl"
                :disabled="bannerForm.bannerType === 1"
                placeholder="请选择"
                value-key="pageUrl"
                class="typeSelect newsSelect"
                filterable
              >
                <el-option
                  v-for="itemSelect in hrefList"
                  :key="itemSelect.pageUrl"
                  :label="itemSelect.title"
                  :value="itemSelect.pageUrl"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-if="bannerForm.targetType === 3" class="typeItem">
              <upPdf
                :pdf-list.sync="pdfList"
                :limit="1"
                :file-name="(pdfList.length > 0 && pdfList[0].name) || ''"
                :path="'pdf/2/null'"
                :disabled="bannerForm.bannerType === 1"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="footer">
        <el-button class="cancel" @click="cancelModal">取消</el-button>
        <el-button class="confirm" @click="confirmModal">保存</el-button>
      </div>
    </el-dialog>
    <Cropper
      :show.sync="showCropper"
      :cropper-img-url="cropperImgUrl"
      :path="filePath"
      :fixed-number="fixedNumber"
      :file-list.sync="fileList"
      @cubeImg="cubeImg"
      @cancelCropper="cancelCropper"
    />
  </div>
</template>
<script>
import { validUsername } from "@/utils/validate";
import { getNewsTitles, getBannerSize } from "@/apis/user.js";
import upPdf from "@/components/uploadPdf/index";
import Cropper from "@/components/cropper";
import NewUpload from "@/components/newUpload";
import { mapGetters } from "vuex";
export default {
  components: {
    Cropper,
    NewUpload,
    upPdf,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    bannerdetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const validateUrl = (rule, value, callback) => {
      // //console.log(value)
      if (validUsername(value)) {
        callback(new Error("请输入URL"));
      } else {
        callback();
      }
    };
    const validateType = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择BANNER类型"));
      } else {
        callback();
      }
    };
    const validateNewsId = (rule, value, callback) => {
      // //console.log(value)
      if (value === "" || value === null) {
        callback(new Error("请选择新闻标题"));
      } else {
        callback();
      }
    };
    return {
      bannerForm: {
        // 表单form
        imageUrl: "",
        targetType: "",
        bannerType: 0, // 图片0视频1
        videoId: "",
        videoUrl: "",
        newsId: "",
        url: "",
        title: "",
        sort: "",
        imageId: "",
        pageUrl: "",
        fileId: "",
      },
      pdfList: [],
      bannerRules: {
        // 表单验证规则
        url: [
          {
            required: true,
            trigger: "blur",
            validator: validateUrl,
          },
        ],
        targetType: [
          {
            required: true,
            trigger: "confirmModal change",
            validator: validateType,
          },
        ],
        newsId: [
          {
            required: true,
            trigger: "confirmModal change",
            validator: validateNewsId,
          },
        ],
        pageUrl: [
          {
            required: true,
            trigger: "confirmModal change",
            message: "请选择页面",
          },
        ],
      },
      bannerTypeList: [
        {
          name: "新闻",
          targetType: 0,
        },
        // {
        //   name: 'HTML',
        //   targetType: 1
        // },
        {
          name: "页面",
          targetType: 2,
        },
        {
          name: "PDF",
          targetType: 3,
        },
      ],
      // bannerType 默认0图片 1视频 ，
      bannerContentList: [
        {
          name: "图片",
          bannerType: 0,
        },
        {
          name: "视频",
          bannerType: 1,
        },
      ],
      fileList: {}, // 预览的信息
      formData: { path: "banner" }, // formData
      titleList: [],
      showCropper: false,
      cropperImgUrl: "",
      fixedNumber: [7, 3],
      filePath: "",
      uploadType: "",
      bannerSize: {},
    };
  },
  computed: {
    ...mapGetters(["hrefList"]),
  },
  watch: {
    bannerdetail(newdata) {
      if (newdata) {
        // //console.log(newdata)
        if (newdata.imageUrl) {
          this.bannerForm = newdata;
          this.bannerForm.targetType =
            this.bannerForm.targetType === -1 ? "" : this.bannerForm.targetType;
          this.bannerForm.url = newdata.url ? newdata.url : "";
          if (this.bannerForm.targetType === 3) {
            const name = newdata.filename;
            if (this.pdfList.length === 0) {
              // this.$set(this.pdfList, 0, )
              this.pdfList = [
                {
                  id: newdata.fileId,
                  name,
                },
              ];
            } else {
              // if (newdata.fileId !== olddata.fileId) {
              this.pdfList[0].id = this.bannerForm.fileId;
              this.pdfList[0].name = name;
              // this.$set(this.pdfList, 0, { name })
              // }
            }
          } else {
            this.pdfList = [];
          }
        } else {
          this.bannerForm = {
            // 表单form
            imageUrl: "",
            targetType: "",
            bannerType: 0, // 图片0视频1
            videoId: "",
            videoUrl: "",
            newsId: "",
            url: "",
            title: "",
            fileId: "",
            sort: newdata.sort,
            imageId: "",
            pageUrl: "",
          };
          this.pdfList = [];
        }
      }
    },
  },
  mounted() {
    getBannerSize().then((res) => {
      this.bannerSize = res.result;
      this.fixedNumber = [
        (res.result.bannerX / res.result.bannerY).toFixed(2),
        1,
      ];
    });
  },
  methods: {
    // 关闭dialog
    cancelModal() {
      if (this.bannerForm.bannerType === 0) {
        this.$refs.uploadWrap.cancelDialog();
      } else {
        this.$refs.uploadWrap2.cancelDialog();
        this.$refs.uploadWrap3.cancelDialog();
      }
      this.$refs.dialogform.clearValidate();
      this.$emit("update:visible", false);
    },
    // 保存dialog
    confirmModal() {
      this.$refs.dialogform.validate((valid) => {
        // 表单验证
        if (valid) {
          if (this.bannerForm.bannerType === 0) {
            if (!this.bannerForm.imageUrl) {
              this.$message1("请上传BANNER图片", "warning");
              return;
            } else {
              this.bannerForm.targetType =
                this.bannerForm.targetType === ""
                  ? -1
                  : this.bannerForm.targetType;
              this.bannerForm.url =
                this.bannerForm.targetType === 0 ? "" : this.bannerForm.url;
              this.bannerForm.newsId =
                this.bannerForm.targetType === 0 ? this.bannerForm.newsId : "";
              this.bannerForm.pageUrl =
                this.bannerForm.targetType === 2 ? this.bannerForm.pageUrl : "";
              // this.bannerForm.fileId =
              //   this.bannerForm.targetType === 3 ? this.pdfList[0].id : ''
              // this.bannerForm.fileUrl =
              //   this.pdfList.length > 0 && this.pdfList[0].url
              this.bannerForm.videoId = this.bannerForm.videoUrl = "";
            }
          } else if (this.bannerForm.bannerType === 1) {
            if (!this.bannerForm.imageUrl) {
              this.$message1("请上传视频封面图", "warning");
              return;
            } else {
              if (!this.bannerForm.videoUrl) {
                this.$message1("请上传视频", "warning");
                return;
              }
            }
            this.bannerForm.targetType = -1;
            this.bannerForm.fileId = this.bannerForm.fileUrl = "";
          }
          if (this.bannerForm.targetType === 3) {
            if (this.pdfList.length === 0) {
              this.$message1("请上传一个文件", "warning");
              return;
            } else {
              this.bannerForm.fileId = this.pdfList[0].id;
              this.bannerForm.fileUrl = this.pdfList[0].url;
              this.bannerForm.filename = this.pdfList[0].name;
            }
          }

          this.$emit("changeBanner", this.bannerForm); // 子传父
          this.$emit("update:visible", false);

          this.$refs.dialogform.clearValidate();
        } else {
          if (this.bannerForm.targetType === "") {
            this.$message1("请选择BANNER链接的内容", "warning");
            return;
          }
          if (
            this.bannerForm.targetType === 0 &&
            this.bannerForm.newsId === ""
          ) {
            this.$message1("请选择新闻标题", "warning");
            return;
          }
          if (
            this.bannerForm.targetType === 1 &&
            validUsername(this.bannerForm.url)
          ) {
            this.$message1("请输入URL", "warning");
            return;
          }
          if (this.bannerForm.targetType === 2 && !this.bannerForm.pageUrl) {
            this.$message1("请选择页面", "warning");
          }
        }
      });
    },
    clear() {
      this.bannerForm.url = "";
      this.bannerForm.newsId = "";
      this.bannerForm.targetType = "";
    },
    // 可能需要用到兄弟组件的传值
    cancelCropper(e) {
      this.showCropper = e;
      this.fileList = {};
    },
    // 选择图片事件的反馈
    chooseImage(e) {
      this.fileList = e.fileList;
      if (e.uploadtype !== "bannerVideo") {
        this.showCropper = true;
        this.cropperImgUrl = e.img;
      }

      this.filePath = e.filePath;
      this.uploadType = e.uploadtype;
    },
    chooseVideo(e) {
      this.bannerForm.videoUrl = e.location;
      this.bannerForm.videoId = e.id;
    },
    cubeImg(e) {
      this.showCropper = false;
      if (this.uploadType === "bannerImage") {
        this.bannerForm.imageUrl = e.imgNew;
        this.bannerForm.imageId = e.id;
      }

      this.fileList = {};
    },

    getNewsTitles(title) {
      getNewsTitles({ title }).then((res) => {
        this.titleList = res.result;
      });
    },
    // 改变类型要清验证
    changeType() {
      this.$refs.dialogform.clearValidate();
    },
    changeBannerType(e) {
      if (e === 0) this.$refs.uploadWrap3.cancelDialog();

      // this.bannerForm.imageUrl = this.bannerForm.imageId = this.bannerForm.videoUrl = this.bannerForm.videoId = ''
    },
  },
};
</script>

<style lang="scss" scoped>
.itemContainer {
  display: flex;
  height: 26px;
  align-items: center;
}
.userIconWrap {
  // width: 26px;
  height: 15px;
  margin-left: 16px;
  // display: inline-block;
  border-right: 0.5px solid #d2d2d2;
  .userIcon {
    width: 11px;
    height: 15px;
    vertical-align: top;
    margin-right: 13px;
  }
}
.vericode {
  height: 14px;
  .userIcon {
    width: 16px;
    height: 14px;
  }
}
.emailWrap {
  display: flex;
  // height:28px;
  align-items: center;
  margin-top: 16px;
  /deep/ .el-form-item {
    margin-top: 0;
  }
}
.emailText {
  margin-left: 12px;
  color: #b3b3b3;
  font-size: 14px;
}
.refreshContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 45px;
  p {
    line-height: 1;
  }
  p:nth-of-type(1) {
    margin-top: 75px;
    font-size: 20px;
    color: #808080;
  }
  p:nth-of-type(2) {
    margin-top: 16px;
    font-size: 14px;
    color: #ccc;
  }
  .el-button {
    margin-top: 65px;
    input {
      font-size: 14px;
    }
  }
}
.tips {
  color: #b3b3b3;
  font-size: 14px;
  line-height: 40px;
  margin-left: 10px;
}
.bannerSelectWrap {
  display: flex;
  margin-top: 20px;
  .bannerUploadWrap {
    display: flex;
  }
}
</style>
